
import { refreshAccessToken } from '../api/loginAPI';
let isRefreshing = false;
let refreshSubscribers: any[] = [];
async function refreshToken() {
  if (isRefreshing) {
    return new Promise((resolve) => {
      addRefreshSubscriber(resolve);
    });
  }
  isRefreshing = true;
  try {
    const res = await refreshAccessToken({
        token: localStorage.getItem("accessToken"),
        deviceId: sessionStorage.getItem("userDeviceToken")
     });
    if (res.data.accessToken) {
      localStorage.setItem('xreftoken', res.data.xRefData);
      localStorage.setItem('accessToken', res.data.accessToken);
      sessionStorage.setItem('userDeviceToken', res.data.deviceId);
      localStorage.setItem('X-REF-DATA', res.data.xRefData);
      refreshSubscribers.forEach(callback => callback(res.data.accessToken));
      refreshSubscribers = [];
      window.location.reload(); // Reload the page to refresh the session
    } else {
      window.location.href = '/logout'; // Handle logout if refresh fails
    }
    return true;
  } catch (error) {
    console.error('Error refreshing token', error);
    return null;
  } finally {
    isRefreshing = false;
  }
}
function addRefreshSubscriber(callback: (newToken: string) => void) {
  refreshSubscribers.push(callback);
}
export const refreshTokenRequest = () => refreshToken();