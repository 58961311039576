import React, { useEffect, useState } from "react";
// @ts-ignore
import { ClaimList } from "@mediassistrepo/claim-list";
import "@mediassistrepo/claim-list/dist/style.css";
import HeaderWeb from "./shared/Header";
import FooterWeb from "./shared/Footer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  familyClaimsAsync,
  getActiveFamilyClaims,
} from "../reducers/familyClaims.slice";
import { useNavigate } from "react-router";

import { ClaimListRequest } from '../interfaces/claimList.interface';
import { claimListAsync, getClaimList, getClaimListLoading } from '../reducers/claimList.slice';
import Loader from './shared/Loader';
import { beneficiariesAsync, getActiveBeneficiaries, getBeneficiaries, setActiveBeneficiaries } from '../reducers/beneficiaries.slice';
import { feedBackCommon, getCookieValue, postToNativeApp } from '../common/helpers';
import { RouteConstant  ,LoggerConstant } from "../common/common.constants";
import {DownloadECard} from '@mediassistrepo/homepage-component';
import { formatDateToObject, getAccessToken } from '../utils';
import { downloadEcardByMember, downloadEcardFile } from "../utils/downloadEcard";
import { getBaseProfile } from "../reducers/baseProfile.slice";
import { useSearchParams } from 'react-router-dom';
import SurveyMain from "../common/Survey/SurveyMain";
import Constants from "../constants/Constants";
import { dataLayerPush } from '../utils/gtm';
import { Helmet } from "react-helmet-async";
import { getConfig } from '../reducers/config.slice';

export default function ClaimListComponent(props: any) {
  const dispatch = useAppDispatch();
  const claimListResponse = useAppSelector(getClaimList);
  const isClaimListLoading = useAppSelector(getClaimListLoading);
  const nativeApp = getCookieValue("source") === "native" ? true : false;
  const beneficiariesResponse = useAppSelector(getBeneficiaries);
  const cachedActiveBeneficiaries  = useAppSelector(getActiveBeneficiaries);
  const accessToken = getAccessToken();
  const [loading, setLoading] = React.useState(false)
  const [claimLoading, setClaimLoading] = React.useState(false);
  const baseProfile = useAppSelector(getBaseProfile);
  const [feedBackModalShow,setFeedBackModalShow]=useState(false);
  const [maid,setMaid]=useState<string>('');
  const [footerClick, setFooterClick] = useState(false);
  const corporateConfig = useAppSelector(getConfig);

  const navigate = useNavigate();

  const [uniqueActiveBenefs, setUniqueActiveBenefs]  = useState(cachedActiveBeneficiaries);


  useEffect(() => {
    const uniqueMaidMap = new Map<Number,Boolean>(); 
    const uniqueActiveBenefsTemp = new Array<any>();
    if(cachedActiveBeneficiaries?.length > 0){
      cachedActiveBeneficiaries.forEach((benef) => {
        if(!uniqueMaidMap.has(benef?.mediAssistId)){
         uniqueActiveBenefsTemp.push(benef);
         uniqueMaidMap.set(benef?.mediAssistId,true);
        }
     });
     setUniqueActiveBenefs(uniqueActiveBenefsTemp);
    }

  }, [cachedActiveBeneficiaries]);
  
   useEffect(() => {
     const component: any = document.querySelector("claim-list-web-component");
     const handleClaimClick = (e: any) => {
       if (e && e?.detail) {
         claimDetailsRedirection(e.detail);
       }
     };

     const handleGtmEvents = (e: any) => {
       dataLayerPush?.(e.detail[0], e.detail[1]);
     };

     if (component) {
       component.addEventListener("CLAIM_CLICK", handleClaimClick);
       component.addEventListener("gtm-events", handleGtmEvents);
     }

     return () => {
       if (component) {
         component.removeEventListener("CLAIM_CLICK", handleClaimClick);
         component.removeEventListener("gtm-events", handleGtmEvents);
       }
     };
   }, []);

  useEffect(() => {
    if (!claimListResponse) {
      getFilteredClaimList(null, true);
    }
  }, []);


  useEffect(() => {
    if(cachedActiveBeneficiaries){
       return;
    }
    const activeBeneficiaries = beneficiariesResponse?.beneficiaryDetails
      ?.filter((benefs) => formatDateToObject(benefs?.policyEndDate) >= new Date());
    postToNativeApp({"activeBenefCount":activeBeneficiaries?.length})

    dispatch(setActiveBeneficiaries(activeBeneficiaries));

  }, [beneficiariesResponse]);
  
  const getFilteredClaimList = (request: ClaimListRequest, isBaseRequest: Boolean = false, filterValue: { id: number[]; type: 'claimTypeIds' | 'maids' | 'policyIds' } | null = null) => {
    if (isBaseRequest) {
      request = {};
    } else if (filterValue?.id?.length) {
      const { id: ids, type } = filterValue;
      if (type === 'claimTypeIds' && request.claimTypeIds) {
        request.claimTypeIds = request.claimTypeIds.filter(claimTypeId => !ids?.includes(claimTypeId));
      }

      if (type === 'maids' && request.maids) {
        request.maids = request.maids.filter(maid => !ids?.includes(maid));
      }

      if (type === 'policyIds' && request.policyIds) {
        request.policyIds = request.policyIds.filter(policyId => !ids?.includes(policyId));
      }
    }

    dispatch(claimListAsync(request));
  };

  useEffect(() => {
    dispatch(beneficiariesAsync());
  }, []);

  const claimDetailsRedirection = async (item: any) => {
    let section_name="Past Claims";
    if (
      !['settled', 'rejected', 'denied', 'repudiated', 'claim settled' ].find(x => item?.status?.toLowerCase().includes(x))) 
    {
      section_name="Active Claims";
    }
    dataLayerPush(Constants.Instance.CLAIMS_POLICIES_CARD_INTERACTION, { 
      section_name,
      card_type : item?.type,
      claim_id: item?.data?.id,
      claim_status:item?.status
    });
    window.location.href = "/claimDetails/" + encodeURIComponent(item?.token);
  };

  useEffect(() => {
    if (getCookieValue('platform') == 'ios') {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
    else if(getCookieValue('platform') == 'android'){
      
      document.addEventListener('message', handleMessage);
      return () => {
        document.removeEventListener('message', handleMessage);
      };
    }
  }, []);
  const handleMessage = (event: any) => {
    const message = JSON.parse(event.data);
    if (message.event === 'urlToWeb') {
      if (message.data.includes('claim-list')) {
        navigate("/claim-list")
      }
      else if (message.data.includes('policy-list')) {
        navigate("/policy-list")
      }
      else if (message.data.includes('account')) {
        navigate("/account")
      }
      else {
        navigate("/")
      }
    }
    else if (message.event === 'EcardSuccess') {
      HandlefeedBack("E_CARD");
    }
  };
  const HandlefeedBack= async (entityType :string)=>{
    const show= await feedBackCommon(entityType)
    setFeedBackModalShow(show);
    setMaid(localStorage.getItem("maid") )
  }
  const openEcard = async()=>{
    if(corporateConfig.isLoadFromCAS){
      await downloadEcardByMember((loading: boolean) => setLoading(loading), baseProfile,[] );
    }else{
      props.setIsEcardOpen(true)
    }
    HandlefeedBack("E_CARD");
  }
  return (
    <>
      <Helmet>
        <title>MAven - Claims</title>
        <meta name="description" content="" />
      </Helmet>
      {(loading) && <Loader />}
      {feedBackModalShow && <div className="relative">
      <SurveyMain pageType="Claim List" pageTitle='E-card successfully downloaded.' Id={maid} source ={"ecard"} entity_Type={"E_CARD"} closeFeedbackModal={() => setFeedBackModalShow(!feedBackModalShow)}/>
      </div>}
      {isClaimListLoading && <Loader />}
      {claimLoading && <Loader />}
     {localStorage.getItem('source') !== Constants.Instance.MEDIBUDDY && <HeaderWeb show={false}    //hide the header in Medibuddy app when opened in external browser 
        handleEcard={openEcard}
        showSubmitClaim={props?.showClaimSubmissionModal}
        handleClickHospital={() => {
        }}
        handleClickClinic={() => {navigate("/claimSubmission/OPD"); navigate(0)}}
        handleClickWellness={() => { navigate("")}}
        onClose={() => {
        props?.setShowClaimSubmissionModal(false)}} />}

      <DownloadECard 
      
insideContainer={`calc(100dvh - 30dvh)`}
styleOutSide={{height:"100vh"}}
sectionName={footerClick? Constants.Instance.BOTTOM_NAVIGATION_BAR:Constants.Instance.TOP_NAVIGATION_BAR} 
      dataLayerPush={dataLayerPush}
        isVisible={props.isEcardOpen}
        toggleBottomModal={() => {
          props.setIsEcardOpen(false)
        }}
        membersData={uniqueActiveBenefs ?? []}
        handleEcardDownload={async (selectedMaids: any) => {
          await downloadEcardByMember((loading: boolean) => setLoading(loading), baseProfile,selectedMaids );
            HandlefeedBack("E_CARD");
        }}
        onCloseClick={() => {
          if (getCookieValue('source') === 'native') {
            postToNativeApp({ "hideFooter": false })
            }
          props.setIsEcardOpen(false);
        }}
      />

      <div className="px-5 mx-auto" style={{ paddingBottom: "100px" }}>
      <claim-list-web-component
          accessToken={accessToken}
        ></claim-list-web-component>
        {(!nativeApp || props.showClaimSubmissionModal) && localStorage.getItem('source') !== Constants.Instance.MEDIBUDDY && <FooterWeb handleEcard={() => {openEcard();setFooterClick(true)}} showSubmitClaim={props.showClaimSubmissionModal} onClose={()=>{        //hide the footer in Medibuddy app when opened in external browser 
         postToNativeApp({ "hideFooter": false })
         props.setShowClaimSubmissionModal(false)
       }}/>}
      </div>
    </>
  );
}
