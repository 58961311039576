import React, { useEffect, useState } from "react";
import { Members } from "@mediassistrepo/homepage-component";
import {
  GaEventDetails,
  getCookieValue,
  postToNativeApp,
} from "../../common/helpers";
import { useNavigate } from "react-router";
import { getMaid } from "../../utils";
import {
  clkEcardDowanloaded,
  clkenrollment,
  Homecategory,
  LoggerConstant,
} from "../../common/common.constants";
import { downloadEcardByMember } from "../../utils/downloadEcard";
import {  BeneficiaryDetail } from "../../interfaces/beneficiaries.interface";
import { ConfigResponse } from "../../interfaces/config.interface";
import { BaseProfile, BaseProfileResponse } from "../../interfaces/baseProfile.interface";
import { useAppSelector } from "../../app/hooks";
import { getConfig } from "../../reducers/config.slice";
import { dataLayerPush } from "../../utils/gtm";
import Constants from "../../constants/Constants";

interface MemberSectionProps {
  activeBenefs: BeneficiaryDetail[];
  isEcardOpen: boolean;
  setIsEcardOpen: Function;
  setLoading: Function;
  baseProfile: BaseProfileResponse;
  HandlefeedBack: Function;
  setSectionName:Function;
  sectionName: string;
  shieldMemberLastDate: any;
  enrollmentDetails: any;
}

const MemberSection = ({
  activeBenefs,
  isEcardOpen,
  setIsEcardOpen,
  setLoading,
  baseProfile,
  HandlefeedBack,
  setSectionName,
  sectionName,
  shieldMemberLastDate,
  enrollmentDetails
}: MemberSectionProps) => {
  
  const navigate = useNavigate();
  const [closeModal, setCloseModal] = useState(false);
  const corporateConfig = useAppSelector(getConfig);
  const [uniqueActiveBenefs, setUniqueActiveBenefs]  = useState(activeBenefs);
   
  useEffect(() => {
    const uniqueMaidMap = new Map<Number,Boolean>(); 
    const uniqueActiveBenefsTemp = new Array<BeneficiaryDetail>();
    if(activeBenefs?.length > 0 ){
      activeBenefs.forEach((benef) => {
        if(!uniqueMaidMap.has(benef?.mediAssistId)){
         uniqueActiveBenefsTemp.push(benef);
         uniqueMaidMap.set(benef?.mediAssistId,true);
        }
     });
     setUniqueActiveBenefs(uniqueActiveBenefsTemp);
    }
  },[activeBenefs]);
  const openEcard = async()=>{
    if(corporateConfig.isLoadFromCAS){
      await downloadEcardByMember((loading: boolean) => setLoading(loading), baseProfile,[] );
      HandlefeedBack("E_CARD");
    }
  }
  
  return (
    <>
      {activeBenefs?.length > 0 && (
        <Members
          // handelEnrolmember= {()=>{navigate('/enrollment')}}
          handleUpdateMemberDetails={()=>{navigate('/family-member-details')}}
          shieldMemberLastDate={shieldMemberLastDate}
          setSectionName={setSectionName}
          sectionName={sectionName}
          dataLayerPush={dataLayerPush}
          handleEnrollclick={() => {
            GaEventDetails(clkenrollment, Homecategory, getMaid(), "");
            navigate("/enrollment");
          }}
          // showEnrollment={false}
          showEnrollment={corporateConfig?.isEnrolmentEnabled}
          beneficiaryDetails={uniqueActiveBenefs || []}
          isCAS = {corporateConfig.isLoadFromCAS}
          isOpenModel={isEcardOpen}
          onCloseClick={() => {
            if (getCookieValue("source") === "native") {
              postToNativeApp({ hideFooter: false });
            }
            setIsEcardOpen(false);
          }}
          toggleBottomModal={() => {
            setIsEcardOpen(false);
          }}
          handleEcardDownload={async (selectedMaids: any) => {
            setCloseModal(false);
            // if (getCookieValue("source") === "native") {
            //   postToNativeApp({ hideFooter: false });
            // }
            GaEventDetails(clkEcardDowanloaded, Homecategory, getMaid(), "");
            await downloadEcardByMember(
              (loading: boolean) => setLoading(loading),
              baseProfile,
              selectedMaids
            );
              HandlefeedBack("E_CARD");
            
            // setIsEcardOpen(false);
            // setCloseModal(true);
          }}
          handleDownloadClick={() => {
            if(corporateConfig.isLoadFromCAS){
              openEcard()
            }
            else if (getCookieValue("source") === "native") {
              postToNativeApp({ hideFooter: true });
            }
       
          }}
          shieldMemberText={corporateConfig?.enrolmentText?.shieldMemberText}
          shieldMemberHeading={corporateConfig?.enrolmentText?.shieldMemberHeading}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default MemberSection;
