// @ts-ignore
import {
    PolicyDetails
} from '@mediassistrepo/policy-details';
import "@mediassistrepo/policy-details/dist/style.css";
import { beneficiariesAsync, getActiveBeneficiaries, getAllBeneficiaries, getBeneficiaries, getBeneficiariesLoading, getBeneficiariesStatus, setAllBenefs } from '../reducers/beneficiaries.slice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FooterWeb from './shared/Footer';
import { getConfig } from '../reducers/config.slice';
import { getPolicyDocuments, policyAsync } from '../reducers/policy.slice';
import { feedBackCommon, getCookieValue, postToNativeApp } from '../common/helpers';
import { RouteConstant, LoggerConstant } from "../common/common.constants";
import Loader from './shared/Loader';
import { MemberdomiBalApi } from '../api/policyAPI';
import { formatDateToObject, setMaid } from '../utils';
import HeaderWeb from './shared/Header';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import { downloadEcardByMember } from '../utils/downloadEcard';
import { DownloadECard } from '@mediassistrepo/homepage-component';


export default function PolicyDetailsComponent(props: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const beneficiaries = useAppSelector(getBeneficiaries)
    const benefStatus = useAppSelector(getBeneficiariesStatus);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const policyDocumentsResponse = useAppSelector(getPolicyDocuments)
    const polid = searchParams.get("policyId");
    const corporateConfig = useAppSelector(getConfig);
    const [loading, setLoading] = useState(true)
    const nativeApp = getCookieValue('source') === 'native' ? true : false;
    const [bsiDetails, setBsiDetails] = useState([]);
    const cachedActiveBeneficiaries  = useAppSelector(getActiveBeneficiaries);
  const baseProfile = useAppSelector(getBaseProfile);
  const [footerClick, setFooterClick] = useState(false);
    const cachedAllBenefs = useAppSelector(getAllBeneficiaries);
  const [uniqueActiveBenefs, setUniqueActiveBenefs]  = useState(cachedAllBenefs);
   
  

    const getDomiBSI = async () => {
        const benefDetails = selectedPolicy.benefDetail.map((benef:any) => {
                return {
                    benefMediAssistID: benef.maid,
                    benefUserID: benef.userId,
                    dbType: benef.sourceDBType,
                }
        });
        const payload = {
            benefDetails: benefDetails
        }
        const response = await MemberdomiBalApi(payload)
        if (response && response.data && response.data.domiBSIDetails) {
            setBsiDetails(response.data.domiBSIDetails)
        }
    }

    useEffect(()=>{
        const allBenefs = beneficiaries?.beneficiaryDetails
        dispatch(setAllBenefs(allBenefs));
    },[beneficiaries])
        

    useEffect(() => {
        const uniqueMaidMap = new Map<Number,Boolean>(); 
        const uniqueActiveBenefsTemp = new Array<any>();
        if(cachedAllBenefs?.length > 0){
          cachedAllBenefs.forEach((benef) => {
            if(!uniqueMaidMap.has(benef?.mediAssistId)){
             uniqueActiveBenefsTemp.push(benef);
             uniqueMaidMap.set(benef?.mediAssistId,true);
            }
         });
         setUniqueActiveBenefs(uniqueActiveBenefsTemp);
        }
    
      },[cachedAllBenefs]);

    useEffect(() => {
        if (!beneficiaries)
            dispatch(beneficiariesAsync());
        else {
            setSelectedPolicy(beneficiaries.policyDetails.filter(x => x.policyId.toString() === polid)[0])
        }
    }, [beneficiaries])

    useEffect(() => {
        if(selectedPolicy && selectedPolicy.benefDetail){
            getDomiBSI();
        }
    },[selectedPolicy])

    useEffect(() => {
        dispatch(policyAsync())
        setLoading(false)
    }, []);
 

    const HandlefeedBack= async (entityType :string)=>{
        const show= await feedBackCommon(entityType)
        setMaid(localStorage.getItem("maid") )
      }
      const openEcard = async()=>{
        if(corporateConfig.isLoadFromCAS){
          await downloadEcardByMember((loading: boolean) => setLoading(loading), baseProfile,[] );
         
        }else{
          props.setIsEcardOpen(true)
        }
        HandlefeedBack("E_CARD");
      }
    return (
        <>
            {loading && <Loader />}
            <HeaderWeb
                show={false}
            />
            {selectedPolicy && policyDocumentsResponse &&  <div className='relative' style={{ padding: "20px 20px 100px 20px", zIndex: 1 }}>
                <div className="pt-6">
                    <PolicyDetails
                        domiBalanceDetails={bsiDetails}
                        policyConfigConditionData={corporateConfig}
                        policyDetails={selectedPolicy}
                        policyDocuments={policyDocumentsResponse}
                        dataLayerPush={dataLayerPush}
                        handleEcard={openEcard}
                        handleDownload={(docPath: any) => {
                            dataLayerPush(Constants.Instance.VIEW_POLICY_DOCUMENTS_CTA_CLICK, { 
                                cta_text:"Download"
                              });
                            if (nativeApp) {
                                if (!docPath.includes("https:")) {
                                    postToNativeApp({ "browserUrl": `${process.env.REACT_APP_MEDIASSIST_PORTAL}${docPath}` })
                                }
                                else {
                                    postToNativeApp({ "browserUrl": docPath })
                                }
                            } else {
                                if (!docPath.includes("https:")) {
                                    let url = new URL(`${process.env.REACT_APP_MEDIASSIST_PORTAL}${docPath}`);
                                    window.open(url)
                                }
                                else {
                                    window.open(docPath)
                                }
                            }
                        }}
                        handlePolicyBackClick={() => { navigate(-1) }}
                    />
                     <div >

                       <DownloadECard 
      
      insideContainer={`calc(100dvh - 30dvh)`}
      styleOutSide={{height:"100vh"}}
      sectionName={footerClick? Constants.Instance.BOTTOM_NAVIGATION_BAR:Constants.Instance.TOP_NAVIGATION_BAR} 
            dataLayerPush={dataLayerPush}
              isVisible={props.isEcardOpen}
              toggleBottomModal={() => {
                props.setIsEcardOpen(false)
              }}
              membersData={uniqueActiveBenefs ?? []}
              handleEcardDownload={async (selectedMaids: any) => {
                  if(corporateConfig.isLoadFromCAS){
                    await downloadEcardByMember((loading: boolean) => setLoading(loading), baseProfile,[] );

                  }
                  else{
                await downloadEcardByMember((loading: boolean) => setLoading(loading), baseProfile,selectedMaids );
                  }
                  HandlefeedBack("E_CARD");
              }}
              onCloseClick={() => {
                if (getCookieValue('source') === 'native') {
                  postToNativeApp({ "hideFooter": false })
                  }
                props.setIsEcardOpen(false);
              }}
            />
                     </div>

                </div>
            </div>}
            {/* <FooterWeb /> */}
        </>
    )
}