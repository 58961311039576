import { postRequest } from "../app/postRequest.service";
import { LoginRequest, LoginResponse, OtpRequest, ResetPasswordRequest, ResetPasswordResponse, TicketRequest, TicketResponse, VerifyOtpRequest,RefreshAccessTokenRequest, RefreshAccessTokenResponse } from "../interfaces/login";
import { getPlatformFromCookie, testEmail } from "../common/helpers";

const platform = getPlatformFromCookie();

// A mock function to mimic making an async request for data
export function loginApi(request: LoginRequest) {
  return new Promise<{ data: LoginResponse }>((resolve) =>
  resolve(postRequest('/auth/username-login', request))
);
}

export function ssoLoginApi(request: LoginRequest) {
  return new Promise<{ data: LoginResponse }>((resolve) =>
    resolve(postRequest('/auth/check-sso-user', request))
  );
}

export function ssoSamlLoginApi(request:any){
  return new Promise<{data:any}>((resolve) => 
    resolve(postRequest('/auth/sso-login', request))
  );
}

export function sendMobileOtp(request: OtpRequest) {
  return new Promise<{ data: LoginResponse }>((resolve) =>
    resolve(postRequest('/auth/otp-mobile', request))
  );
}

export function multiUserLogin(request: any){
  return new Promise<{data: any}>((resolve) => {
    resolve(postRequest('/auth/multiuser-details', request))
  })
}

export function multiUserLoginAccessToken(request: any){
  return new Promise<{data: any}>((resolve) => {
    resolve(postRequest('/auth/multiuser-login', request))
  })
}

export function sendEmailOtp(request: OtpRequest) {
  return new Promise<{ data: LoginResponse }>((resolve) =>
    resolve(postRequest('/auth/otp-email', request))
  );
}

export function verifyOtp(request: VerifyOtpRequest) {
  return new Promise<{ data: LoginResponse }>((resolve) =>
  resolve(postRequest('/auth/otp-verify', request))
);
}

export function unlinkUser(request: any) {
  return new Promise<{ data: LoginResponse }>((resolve) =>
    resolve(postRequest('/auth/unlink', request))
  );
}

export function createTicketApi(url :string,request: TicketRequest) {
  return new Promise<{ data: TicketResponse }>((resolve) =>
    resolve(postRequest(url, request))
  );
}

export function resetPasswordApi(payload: ResetPasswordRequest){
  return new Promise<{ data: ResetPasswordResponse }>((resolve) =>
    resolve(postRequest('/auth/reset-password',payload))
  );
}




export function captureConsentApi() {
  return new Promise<{ data: any }>((resolve) =>
    resolve(postRequest("/profile/capture-consent",{}))
  );
}
export function refreshAccessToken(payload: RefreshAccessTokenRequest){
  return new Promise<{ data: RefreshAccessTokenResponse }>((resolve) =>
    resolve(postRequest('/auth/device-login',payload))
  );
}